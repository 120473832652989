import { ChangeEvent, useState } from 'react'
import { InputOnChangeData } from 'semantic-ui-react'
import classNames from 'classnames'

import { Form } from 'client/semantic-imports'
import { AppIcon, AppFormFieldLabel } from 'client/ui-building-blocks'

import styles from './app-form-input.module.scss'

type AppFormInputProps = {
  id?: string
  type?: 'text' | 'password'
  placeholder?: string
  maxLength?: number
  autoComplete?: string
  spellCheck?: boolean
  label: string
  disabled?: boolean
  error?: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
  onClearText?: () => void
}

const AppFormInput = (props: AppFormInputProps) => {
  const [displayPassword, toggleDisplayPassword] = useState<boolean>(false)

  const {
    id,
    type = 'text',
    placeholder = '',
    maxLength,
    autoComplete = 'off',
    spellCheck = false,
    label = '',
    disabled = false,
    error = '',
    value,
    onChange,
    onClearText,
  } = props

  const isPassword = type === 'password'
  const isClearable = Boolean(onClearText)

  const handlePasswordVisibilityToggle = () => {
    toggleDisplayPassword(!displayPassword)
  }

  const hasIcon = isPassword || isClearable

  return (
    <div
      className={classNames(
        styles.appFormInput,
        hasIcon && styles.hasIcon,
        error && 'error',
        'appFormInput'
      )}
    >
      <Form.Input
        id={id}
        type={type === 'password' && displayPassword ? 'text' : type}
        placeholder={placeholder}
        maxLength={maxLength}
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        disabled={disabled}
        label={<AppFormFieldLabel label={label} error={error} />}
        value={value}
        onChange={onChange}
      />
      <div className={styles.iconContainer}>
        {isPassword && !isClearable && (
          <AppIcon
            name={displayPassword ? 'displayPassword' : 'hidePassword'}
            onClick={handlePasswordVisibilityToggle}
            disabled={disabled}
            pointer
          />
        )}
        {!isPassword && isClearable && value && (
          <AppIcon name="close" onClick={onClearText} disabled={disabled} pointer />
        )}
      </div>
    </div>
  )
}

export default AppFormInput
