import { AVATAR_SIZE } from 'client/utils'

import { IconName, Size } from './types'

export const DEBUG_MODE = false

export const AVATAR_DISPLAY_SIZE = {
  small: { width: '55px', height: '55px' },
  medium: { width: '90px', height: '90px' },
  large: { width: '240px', height: '240px' },
  xlarge: { width: AVATAR_SIZE + 'px', height: AVATAR_SIZE + 'px' },
} as Record<Size, { width: string; height: string }>

export const APP_ICONS = {
  avatar: 'picture',
  bug: 'bug',
  checked: 'check',
  close: 'close',
  copy: 'copy outline',
  cube: 'cube',
  deactivation: 'user delete',
  displayPassword: 'eye',
  dropdown: 'dropdown',
  external: 'share square',
  follow: 'eye',
  forward: 'arrow right',
  fullAccount: 'star',
  hidePassword: 'eye slash',
  idea: 'idea',
  keyboard: 'keyboard outline',
  login: 'user circle',
  moveToFirst: 'angle double left',
  moveToLast: 'angle double right',
  next: 'right chevron',
  password: 'lock',
  payment: 'credit card outline',
  pointer: 'hand pointer',
  prev: 'left chevron',
  question: 'question circle',
  random: 'random',
  recover: 'unlock',
  register: 'user plus',
  remove: 'remove',
  scrollTop: 'angle double up',
  search: 'search',
  security: 'protect',
  settings: 'setting',
  share: 'share',
  terminated: 'stop circle outline',
  thumbsUp: 'thumbs up',
  thumbsDown: 'thumbs down',
  unfollow: 'eye slash',
  user: 'user',
  waiting: 'hourglass two',
  warning: 'warning sign',
  write: 'write',
} as Record<IconName, string>
