import { ReactNode } from 'react'
import classNames from 'classnames'

import { AppParagraph, AppIcon, TextColor, TextDecoration, Size, AppDivider } from '..'

import { Theme } from 'client/utils'

import { AppIconProps } from '../AppIcon/app-icon'

import styles from './app-header.module.scss'

export type AppHeaderProps = {
  text: string
  size?: Size
  marginBottom?: boolean | Size
  color?: TextColor
  decoration?: TextDecoration
  dividing?: boolean
  centered?: boolean
  loading?: boolean
  disabled?: boolean
  highlightOnHover?: boolean
  rightSlot?: ReactNode
  theme?: Theme
  dataTestId?: string
  onClick?: (e) => void
  iconProps?: AppIconProps & { position?: 'left' | 'right' }
}

const AppHeader = (props: AppHeaderProps) => {
  const {
    text,
    size = 'medium',
    marginBottom = false,
    color,
    decoration,
    dividing = false,
    centered = false,
    loading = false,
    disabled = false,
    highlightOnHover = false,
    rightSlot = null,
    theme,
    dataTestId,
    onClick,
    iconProps,
  } = props

  const Icon = () => {
    return (
      <div className={classNames(styles.iconContainer, styles[iconProps.position])}>
        <AppIcon
          {...iconProps}
          size={iconProps.size || size}
          pointer={Boolean(iconProps.onClick)}
        />
      </div>
    )
  }

  return (
    <>
      <div className={classNames(styles.appHeader, centered && styles.centered)}>
        <div className={styles.headerContent}>
          {iconProps && [undefined, 'left'].includes(iconProps.position) ? (
            <Icon />
          ) : null}
          <div className={styles.paragraphContainer}>
            <AppParagraph
              isHeader
              text={text}
              size={size}
              color={color}
              decoration={decoration}
              loading={loading}
              disabled={disabled}
              highlightOnHover={highlightOnHover}
              theme={theme}
              dataTestId={dataTestId}
              onClick={onClick}
            />
          </div>
          {iconProps?.position === 'right' ? <Icon /> : null}
          {!loading && rightSlot && <div className={styles.rightSlot}>{rightSlot}</div>}
        </div>
      </div>
      {dividing && <AppDivider border size="xsmall" />}
      {marginBottom && (
        <AppDivider size={marginBottom === true ? 'medium' : marginBottom} />
      )}
    </>
  )
}

export default AppHeader
