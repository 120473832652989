import { ReactNode } from 'react'
import { Popup } from 'client/semantic-imports'

import styles from './app-popup.module.scss'

type AppPopupProps = {
  children: ReactNode
  text: string
  position?: 'top center' | 'top left' | 'top right' | 'left center' | 'right center'
}

const AppPopup = (props: AppPopupProps) => {
  const { children, text, position = 'top center' } = props

  if (!text) {
    return children
  }

  return (
    <Popup
      className={styles.appPopup}
      content={text}
      position={position}
      mouseEnterDelay={0}
      mouseLeaveDelay={0}
      trigger={<div className={styles.trigger}>{children}</div>}
      inverted
    />
  )
}

export default AppPopup
