// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iQJ0QfJxFy2LvfW9spf3{min-height:300px}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppList/app-list.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA","sourcesContent":[".appList {\n  min-height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appList": `iQJ0QfJxFy2LvfW9spf3`
};
export default ___CSS_LOADER_EXPORT___;
