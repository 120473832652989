// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JaUdbXrr70hYtRn_hDxf{margin:0 !important}.JaUdbXrr70hYtRn_hDxf.TALuG2Hmr7fUZu5nh2PP{color:var(--icon) !important}.JaUdbXrr70hYtRn_hDxf.b96f7vO_V3haBNOvuwwE{opacity:.5 !important}.JaUdbXrr70hYtRn_hDxf.PstUgHb3jar9BJyrcWkM{cursor:pointer !important}.JaUdbXrr70hYtRn_hDxf.KcSqabWL7diQkKW7GiAH{font-size:var(--icon-small) !important}.JaUdbXrr70hYtRn_hDxf.bid71aejPjT3U3nUywYg{font-size:var(--icon-medium) !important}.JaUdbXrr70hYtRn_hDxf.GZikThidOHadLy4DugmQ{font-size:var(--icon-large) !important}.JaUdbXrr70hYtRn_hDxf.Qk4BTSvhSmomVTUvIj0q{font-size:var(--icon-xlarge) !important}.JaUdbXrr70hYtRn_hDxf.JNf0NSyz2QoJvj_V6723{font-size:var(--icon-xxlarge) !important}.JaUdbXrr70hYtRn_hDxf.KcSqabWL7diQkKW7GiAH,.JaUdbXrr70hYtRn_hDxf.bid71aejPjT3U3nUywYg,.JaUdbXrr70hYtRn_hDxf.GZikThidOHadLy4DugmQ,.JaUdbXrr70hYtRn_hDxf.Qk4BTSvhSmomVTUvIj0q,.JaUdbXrr70hYtRn_hDxf.JNf0NSyz2QoJvj_V6723{height:auto}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppIcon/app-icon.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAEA,2CACE,4BAAA,CAGF,2CACE,qBAAA,CAGF,2CACE,yBAAA,CAIF,2CACE,sCAAA,CAGF,2CACE,uCAAA,CAGF,2CACE,sCAAA,CAGF,2CACE,uCAAA,CAGF,2CACE,wCAAA,CAGF,uNAKE,WAAA","sourcesContent":[".appIcon {\n  margin: 0 !important;\n\n  &.defaultColor {\n    color: var(--icon) !important;\n  }\n\n  &.disabled {\n    opacity: 0.5 !important;\n  }\n\n  &.pointer {\n    cursor: pointer !important;\n  }\n\n  // size\n  &.small {\n    font-size: var(--icon-small) !important;\n  }\n\n  &.medium {\n    font-size: var(--icon-medium) !important;\n  }\n\n  &.large {\n    font-size: var(--icon-large) !important;\n  }\n\n  &.xlarge {\n    font-size: var(--icon-xlarge) !important;\n  }\n\n  &.xxlarge {\n    font-size: var(--icon-xxlarge) !important;\n  }\n\n  &.small,\n  &.medium,\n  &.large,\n  &.xlarge,\n  &.xxlarge {\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appIcon": `JaUdbXrr70hYtRn_hDxf`,
	"defaultColor": `TALuG2Hmr7fUZu5nh2PP`,
	"disabled": `b96f7vO_V3haBNOvuwwE`,
	"pointer": `PstUgHb3jar9BJyrcWkM`,
	"small": `KcSqabWL7diQkKW7GiAH`,
	"medium": `bid71aejPjT3U3nUywYg`,
	"large": `GZikThidOHadLy4DugmQ`,
	"xlarge": `Qk4BTSvhSmomVTUvIj0q`,
	"xxlarge": `JNf0NSyz2QoJvj_V6723`
};
export default ___CSS_LOADER_EXPORT___;
