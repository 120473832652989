import {
  AppSegment,
  AppMessageBox,
  AppHeader,
  AppImage,
  AppDivider,
} from 'client/ui-building-blocks'

import { getAssetUrl } from 'client/services'

const MessageBoxPreview = () => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Basic', marginBottom: 'small' }}>
        <AppMessageBox
          paragraphs={['This is a simple message box with a single paragraph.']}
        />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Complex', marginBottom: 'small' }}>
        <AppMessageBox
          headerNode={<AppHeader text="Message box header" />}
          paragraphs={['- paragraph 1', '- paragraph 2', '- paragraph 3']}
        />
      </AppSegment>
      <AppSegment headerProps={{ text: 'With an image', marginBottom: 'small' }}>
        <AppMessageBox
          headerNode={<AppHeader text="Message box header" />}
          paragraphs={['- paragraph 1', '- paragraph 2', '- paragraph 3']}
          imgNode={
            <AppImage src={getAssetUrl('logo.png')} width="175px" height="185px" />
          }
        />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Size', marginBottom: 'small' }}>
        <AppMessageBox size="small" paragraphs={['This is a small message box.']} />
        <AppDivider size="small" />
        <AppMessageBox size="large" paragraphs={['This is a large message box.']} />
      </AppSegment>
    </>
  )
}

export default MessageBoxPreview
