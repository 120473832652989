import classNames from 'classnames'

import { Button } from 'client/semantic-imports'

import { IconName, Size, ButtonVariant, ButtonType, Position, AppIcon } from '..'

import styles from './app-button.module.scss'
import classes from '../classes.module.scss'

type AppButtonProps = {
  variant?: ButtonVariant
  text?: string
  size?: Size
  iconName?: IconName
  href?: string
  position?: Position
  textPosition?: Position
  iconPosition?: 'left' | 'right'
  fullWidth?: boolean
  dataTestId?: string
  ariaLabel?: string
  // native
  id?: string
  type?: ButtonType
  disabled?: boolean
  loading?: boolean
  onClick?: (e?) => void
}

const AppButton = (props: AppButtonProps) => {
  const {
    variant = 'filled',
    text,
    size = 'medium',
    iconName,
    href,
    position = 'left',
    textPosition = 'center',
    iconPosition = 'left',
    fullWidth = false,
    dataTestId = 'app-button',
    ariaLabel,
    ...rest
  } = props

  const Icon = () => {
    return (
      <span
        data-testid="app-button-icon"
        style={{
          visibility: rest.loading ? 'hidden' : 'visible',
        }}
      >
        <AppIcon name={iconName} />
      </span>
    )
  }

  const Btn = () => (
    <div className={classes[position]}>
      <Button
        className={classNames(
          styles.appButton,
          styles[variant],
          styles[size],
          styles[`textPosition_${textPosition}`],
          classes[position]
        )}
        fluid={fullWidth}
        data-testid={dataTestId}
        aria-label={ariaLabel}
        {...rest}
      >
        <span className={styles.content}>
          {iconName && iconPosition === 'left' && <Icon />}
          {text && <span className={styles.text}>{text}</span>}
          {iconName && iconPosition === 'right' && <Icon />}
        </span>
      </Button>
    </div>
  )

  if (href) {
    return (
      <a href={href} target="_blank" rel="noreferrer">
        <Btn />
      </a>
    )
  } else {
    return <Btn />
  }
}

export default AppButton
