import { AppHeader, AppSegment } from 'client/ui-building-blocks'
import { Theme } from 'client/utils'

type Props = {
  theme: Theme
}

const HeaderPreview = ({ theme }: Props) => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Size', marginBottom: 'small' }}>
        <AppHeader text="Small" size="small" marginBottom="xsmall" centered />
        <AppHeader text="Medium" size="medium" marginBottom="xsmall" centered />
        <AppHeader text="Large" size="large" marginBottom="xsmall" centered />
        <AppHeader text="Extra large" size="xlarge" marginBottom="xsmall" centered />
        <AppHeader text="Double extra large" size="xxlarge" centered />
      </AppSegment>
      <AppSegment headerProps={{ text: 'With an icon', marginBottom: 'small' }}>
        <AppHeader
          text="Small"
          size="small"
          iconProps={{ name: 'avatar' }}
          marginBottom="xsmall"
          centered
        />
        <AppHeader
          text="Medium"
          size="medium"
          iconProps={{ name: 'bug' }}
          marginBottom="xsmall"
          centered
        />
        <AppHeader
          text="Large"
          size="large"
          iconProps={{ name: 'checked' }}
          marginBottom="xsmall"
          centered
        />
        <AppHeader
          text="Extra large"
          size="xlarge"
          iconProps={{ name: 'close' }}
          marginBottom="xsmall"
          centered
        />
        <AppHeader
          text="Double extra large"
          size="xxlarge"
          iconProps={{ name: 'copy' }}
          centered
        />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Loading', marginBottom: 'small' }}>
        <AppHeader
          text="Header text"
          loading
          size="small"
          marginBottom="xsmall"
          theme={theme}
        />
        <AppHeader
          text="Header text"
          loading
          size="medium"
          marginBottom="xsmall"
          theme={theme}
        />
        <AppHeader
          text="Header text"
          loading
          size="large"
          marginBottom="xsmall"
          theme={theme}
        />
        <AppHeader
          text="Header text"
          loading
          size="xlarge"
          marginBottom="xsmall"
          theme={theme}
        />
        <AppHeader text="Header text" loading size="xxlarge" theme={theme} />
      </AppSegment>
    </>
  )
}

export default HeaderPreview
