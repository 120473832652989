import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'

import { USER_MIN_DATE_OF_BIRTH } from 'atw-shared/utils'

import {
  AppFormInput,
  AppFormSelect,
  AppDatePicker,
  AppSegment,
  AppForm,
  AppGroup,
  AppButton,
} from 'client/ui-building-blocks'

const FormPreview = () => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      gender: 'female',
      dateOfBirth: '',
    },
  })

  const onSubmit = handleSubmit(data => {
    alert(JSON.stringify(data, undefined, 4))
  })

  const onClear = () => {
    reset()
  }

  return (
    <AppSegment headerProps={{ text: 'Registration', marginBottom: 'small' }}>
      <AppForm onSubmit={onSubmit}>
        <Controller
          control={control}
          name="firstName"
          rules={{
            required: t('common.validation.required'),
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <AppFormInput
                label="First name"
                value={value}
                onChange={onChange}
                error={errors.firstName?.message}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="lastName"
          rules={{
            required: t('common.validation.required'),
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <AppFormInput
                label="Last name"
                value={value}
                onChange={onChange}
                error={errors.lastName?.message}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="gender"
          rules={{
            required: t('common.validation.required'),
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <AppFormSelect
                label="Gender"
                value={value}
                options={[
                  { value: 'female', text: 'Female' },
                  { value: 'male', text: 'Male' },
                ]}
                onChange={onChange}
                error={errors.gender?.message}
              />
            )
          }}
        />
        <Controller
          control={control}
          name="dateOfBirth"
          rules={{
            required: t('common.validation.required'),
          }}
          render={({ field: { value, onChange } }) => {
            return (
              <AppDatePicker
                label="Date of birth"
                value={value}
                minDate={new Date(USER_MIN_DATE_OF_BIRTH)}
                onChange={onChange}
                error={errors.dateOfBirth?.message}
              />
            )
          }}
        />
        <AppGroup fullWidth>
          <AppButton text="Submit" />
          <AppButton
            text="Clear"
            variant="outlined"
            disabled={!isDirty && isEmpty(errors)}
            onClick={onClear}
          />
        </AppGroup>
      </AppForm>
    </AppSegment>
  )
}

export default FormPreview
