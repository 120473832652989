import { AppSegment, AppLoader } from 'client/ui-building-blocks'

import styles from './loader-preview.module.scss'

const LoaderPreview = () => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Default', marginBottom: 'small' }}>
        <AppLoader />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Absolutely centered', marginBottom: 'small' }}>
        <div className={styles.loaderContainer}>
          <AppLoader absolute />
        </div>
      </AppSegment>
    </>
  )
}

export default LoaderPreview
