import { useEffect, useState } from 'react'

import { readFromBucket } from 'client/aws'
import { getBlob, setBlob, imgResToBlobUrl } from 'client/services'

import { AVATAR_DISPLAY_SIZE, AppImage, Size } from '..'

type Props = {
  src: string
  size?: Size
  rounded?: boolean
  defaultImg?: string
  onClick?: () => void
}

const AppBlobImage = ({
  src,
  size = 'medium',
  rounded = true,
  defaultImg,
  onClick,
}: Props) => {
  const [blobUrl, setBlobUrl] = useState(null)
  const [isMounted, setIsMounted] = useState<boolean>(true)

  const loadImg = (imgSrc: string) => {
    const img = new Image()
    img.onload = () => setTimeout(() => setBlobUrl(imgSrc), 500)
    img.onerror = () => setBlobUrl(null)
    img.src = imgSrc
  }

  useEffect(() => {
    return () => {
      setIsMounted(false)
    }
  }, [])

  useEffect(() => {
    if (src) {
      const blobToSet = getBlob(src)

      if (blobToSet === undefined) {
        setBlobUrl(null)
        readFromBucket(src).then(
          res => {
            if (isMounted) {
              const url = imgResToBlobUrl(res)
              setBlob(src, url)
              loadImg(url)
            }
          },
          () => {
            isMounted && setBlob(src, null)
          }
        )
      } else {
        setBlobUrl(blobToSet)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, defaultImg])

  const handleOnClick = e => {
    e.stopPropagation()
    onClick()
  }

  return src && blobUrl ? (
    <div data-testid="loaded-image">
      <AppImage
        src={blobUrl}
        onClick={onClick ? handleOnClick : null}
        {...AVATAR_DISPLAY_SIZE[size]}
        rounded={rounded}
        shadow
        centered
        alt="user-avatar"
      />
    </div>
  ) : (
    <div data-testid="default-image">
      <AppImage
        src={defaultImg}
        onClick={onClick ? handleOnClick : null}
        {...AVATAR_DISPLAY_SIZE[size]}
        rounded={rounded}
        centered
        alt="avatar-default"
      />
    </div>
  )
}

export default AppBlobImage
