// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wAl088NMnNPVrmeaj0gg{display:flex;align-items:center;cursor:pointer;padding:5px 0;margin-right:15px}.PXeeClxLxpERcDxiQNdm{padding:0 8px 0 0 !important}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppFormRadio/app-form-radio.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,cAAA,CACA,aAAA,CACA,iBAAA,CAGF,sBACE,4BAAA","sourcesContent":[".optionContainer {\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  padding: 5px 0;\n  margin-right: 15px;\n}\n\n.radio {\n  padding: 0 8px 0 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"optionContainer": `wAl088NMnNPVrmeaj0gg`,
	"radio": `PXeeClxLxpERcDxiQNdm`
};
export default ___CSS_LOADER_EXPORT___;
