import { ChangeEvent } from 'react'
import { CheckboxProps } from 'semantic-ui-react'

import { Form } from 'client/semantic-imports'
import { AppFormFieldLabel, AppParagraph } from 'client/ui-building-blocks'

import styles from './app-form-radio.module.scss'

type AppFormRadioProps = {
  label: string
  options: { value: string; label: string }[]
  currentValue: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: string
}

const AppFormRadio = (props: AppFormRadioProps) => {
  const {
    label,
    options = [],
    currentValue,
    onChange,
    disabled = false,
    error = '',
  } = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>, data: CheckboxProps) => {
    if (e) {
      e.target.value = String(data.value)
      onChange(e)
    }
  }

  return (
    <div className="appFormRadio">
      <Form.Field
        label={<AppFormFieldLabel label={label} error={error} />}
        style={{ margin: 0 }}
        disabled={disabled}
      />
      <Form.Group inline>
        {options.map(({ value, label }) => (
          <Form.Checkbox
            key={value}
            label={
              <div className={styles.optionContainer}>
                <Form.Radio
                  className={styles.radio}
                  value={value}
                  checked={value === currentValue}
                />
                <AppParagraph text={label} />
              </div>
            }
            value={value}
            checked={value === currentValue}
            onChange={handleChange}
            disabled={disabled}
          />
        ))}
      </Form.Group>
    </div>
  )
}

export default AppFormRadio
