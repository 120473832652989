import { createContext, useContext, useState, ReactNode } from 'react'

import { AccountStatus, Filter, IUser } from 'atw-shared/utils'

type TAppContext = {
  isAppLoading: boolean
  setIsAppLoading: (isLoading: boolean) => void
  showAppDimmer: boolean
  setShowAppDimmer: (showAppDimmer: boolean) => void
  isInReanswerMode: boolean
  setIsInReanswerMode: (isInReanswerMode: boolean) => void
  listActiveQuestionId: string | null
  setListActiveQuestionId: (id: string | null) => void
  randomQuestionIndex: number | null
  setRandomQuestionIndex: (index: number | null) => void
  recoverPassToken: string
  setRecoverPassToken: (token: string) => void
  redirectQuestionId: string
  setRedirectQuestionId: (url: string) => void
  accountStatus: AccountStatus
  setAccountStatus: (status: AccountStatus) => void
  settingsPageState: SettingsPageState
  setSettingsPageState: (state: SettingsPageState) => void
  browseQuestionsPageState: BrowseQuestionsPageState
  setBrowseQuestionsPageState: (
    state: (state: BrowseQuestionsPageState) => BrowseQuestionsPageState
  ) => void
  authUser: IUser | null
  setAuthUser: (user: IUser) => void
}

const AppContext = createContext<TAppContext>({} as TAppContext)

type Props = {
  children: ReactNode
}

type SettingsPageState = {
  tabNo: number
}

type BrowseQuestionsPageState = {
  tabKey: Filter
  user: IUser | null
  pageNo: number
  categories: []
  search: string
}

export const AppContextProvider = ({ children }: Props) => {
  const [isAppLoading, setIsAppLoading] = useState<boolean>(true)
  const [showAppDimmer, setShowAppDimmer] = useState<boolean>(false)
  const [isInReanswerMode, setIsInReanswerMode] = useState<boolean>(false)
  const [listActiveQuestionId, setListActiveQuestionId] = useState<string | null>(null)
  const [randomQuestionIndex, setRandomQuestionIndex] = useState<number>(null)
  const [recoverPassToken, setRecoverPassToken] = useState<string>('')
  const [redirectQuestionId, setRedirectQuestionId] = useState<string | null>(null)
  const [accountStatus, setAccountStatus] = useState<AccountStatus | null>(null)
  const [settingsPageState, setSettingsPageState] = useState<SettingsPageState>({
    tabNo: 1,
  })

  const [browseQuestionsPageState, setBrowseQuestionsPageState] =
    useState<BrowseQuestionsPageState>({
      tabKey: Filter.NotAnswered,
      user: null,
      pageNo: 1,
      categories: [],
      search: '',
    })

  const [authUser, setAuthUser] = useState<IUser | null>(null)

  return (
    <AppContext.Provider
      value={{
        isAppLoading,
        setIsAppLoading,
        showAppDimmer,
        setShowAppDimmer,
        isInReanswerMode,
        setIsInReanswerMode,
        listActiveQuestionId,
        setListActiveQuestionId,
        randomQuestionIndex,
        setRandomQuestionIndex,
        recoverPassToken,
        setRecoverPassToken,
        redirectQuestionId,
        setRedirectQuestionId,
        accountStatus,
        setAccountStatus,
        settingsPageState,
        setSettingsPageState,
        browseQuestionsPageState,
        setBrowseQuestionsPageState,
        authUser,
        setAuthUser,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  return useContext(AppContext)
}
