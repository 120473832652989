import { ReactNode, useState } from 'react'
import isArray from 'lodash/isArray'

import { AppButton, AppGroup, IconName } from '..'

import styles from './app-fluid-menu.module.scss'

type MenuItem = {
  label: string
  icon?: string
  disabled?: boolean
  hide?: boolean
  onClick: (itemIndex: number) => boolean
}

type Props = {
  children: ReactNode[] | ReactNode
  menuItems?: MenuItem[]
  defaultItemIndex?: number
}

const AppFluidMenu = ({ children, menuItems = [], defaultItemIndex = -1 }: Props) => {
  const [activeItemIndex, setActiveItemIndex] = useState<number>(defaultItemIndex)

  return (
    <div data-testid="app-fluid-menu" className={styles.appFluidMenu}>
      {menuItems.length ? (
        <div className={styles.menu}>
          <AppGroup fullWidth alignment="vertical" gapSize="xsmall">
            {menuItems.map(({ label, icon, disabled, hide, onClick }, i) => {
              if (hide) return null

              const onItemClick = () => {
                if (onClick(i)) {
                  setActiveItemIndex(i)
                }
              }

              const isActive = activeItemIndex !== -1 && i === activeItemIndex

              return (
                <div data-testid="menu-item" key={i}>
                  <AppButton
                    variant={isActive ? 'filled' : 'blank'}
                    data-testid="fluid-menu-btn"
                    fullWidth
                    textPosition="left"
                    disabled={disabled}
                    text={label}
                    iconName={icon as IconName}
                    onClick={onItemClick}
                  />
                </div>
              )
            })}
          </AppGroup>
        </div>
      ) : null}
      <div className={styles.content}>
        {isArray(children) ? (
          children.map(
            (child, i) =>
              i === activeItemIndex && (
                <div key={i} className={styles.childrenContainer}>
                  {child}
                </div>
              )
          )
        ) : (
          <div className={styles.childrenContainer}>{children}</div>
        )}
      </div>
    </div>
  )
}

export default AppFluidMenu
