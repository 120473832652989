import classNames from 'classnames'

import { Label } from 'client/semantic-imports'
import { AppIcon, IconName, Size, ButtonVariant } from 'client/ui-building-blocks'

import styles from './app-chip.module.scss'
import classes from '../classes.module.scss'

type AppChipProps = {
  text?: string
  variant?: ButtonVariant
  size?: Size
  iconName?: IconName
  centered?: boolean
  onIconClick?: () => void
}

const AppChip = (props: AppChipProps) => {
  const {
    text = '',
    variant = 'filled',
    size = 'medium',
    iconName,
    centered = false,
    onIconClick = null,
  } = props

  return (
    <Label
      data-testid="app-chip"
      className={classNames(
        styles.appChip,
        styles[variant],
        styles[size],
        centered && classes.center
      )}
      content={
        <div className={styles.content}>
          {text ? <div>{text}</div> : null}
          {iconName && (
            <AppIcon
              name={iconName}
              size={size}
              onClick={onIconClick}
              pointer={Boolean(onIconClick)}
            />
          )}
        </div>
      }
    />
  )
}

export default AppChip
