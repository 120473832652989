import classNames from 'classnames'

import { Theme } from 'client/utils'
import { AppDivider, AppPlaceHolder } from 'client/ui-building-blocks'

import { TextColor, TextDecoration, Size } from '..'

import styles from './app-paragraph.module.scss'
import classes from '../classes.module.scss'

type AppParagraphProps = {
  isHeader?: boolean
  text: string
  size?: Size
  marginBottom?: boolean | Size
  color?: TextColor
  decoration?: TextDecoration
  centered?: boolean
  loading?: boolean
  disabled?: boolean
  highlightOnHover?: boolean
  theme?: Theme
  dataTestId?: string
  onClick?: (e) => void
}

const AppParagraph = (props: AppParagraphProps) => {
  const {
    isHeader = false,
    text,
    size = 'medium',
    marginBottom = false,
    color = 'primary',
    decoration,
    centered = false,
    loading = false,
    disabled = false,
    highlightOnHover = false,
    theme,
    dataTestId,
    onClick,
  } = props

  const elementClasses = classNames(
    styles.appParagraph,
    styles[size],
    styles[color],
    centered && classes.textCenter,
    !disabled && highlightOnHover && styles.highlightOnHover,
    decoration && styles[decoration],
    !disabled && onClick && styles.pointer,
    loading && styles.loading,
    disabled && styles.disabled
  )

  const elementProps = {
    className: elementClasses,
    onClick,
    'data-testid': dataTestId,
  }

  const elementContent = !loading ? text : '...'

  return (
    <>
      <AppPlaceHolder loading={loading} theme={theme}>
        {!isHeader ? (
          <p className={elementClasses} {...elementProps}>
            {elementContent}
          </p>
        ) : (
          <h1 className={elementClasses} {...elementProps}>
            {elementContent}
          </h1>
        )}
      </AppPlaceHolder>
      {marginBottom && (
        <AppDivider size={marginBottom === true ? 'small' : marginBottom} />
      )}
    </>
  )
}

export default AppParagraph
