import { ReactNode } from 'react'
import classNames from 'classnames'

import { AppDivider } from '..'

import styles from './app-section-layout.module.scss'

type AppSectionLayoutProps = {
  children: ReactNode[]
  border?: boolean
  inverted?: boolean
  autoWrap?: boolean
  padded?: boolean
}

const AppSectionLayout = (props: AppSectionLayoutProps) => {
  const {
    children,
    border = false,
    inverted = false,
    autoWrap = false,
    padded = false,
  } = props

  return (
    <div
      className={classNames(
        styles.appSectionLayout,
        autoWrap && styles.autoWrap,
        padded && styles.padded
      )}
    >
      {children.map((child: ReactNode, i: number) => (
        <div
          key={i}
          className={classNames(
            styles.childContainer,
            border && styles.border,
            inverted && styles.inverted
          )}
        >
          <AppDivider size="small" />
          {child}
          <AppDivider size="small" />
        </div>
      ))}
    </div>
  )
}

export default AppSectionLayout
