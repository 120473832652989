import { SyntheticEvent } from 'react'
import Slider from 'rsuite/cjs/Slider/Slider'
import RangeSlider, { Range } from 'rsuite/cjs/RangeSlider/RangeSlider'

import AppParagraph from '../AppParagraph/app-paragraph'
import Mark from './mark'

import styles from './app-form-slider.module.scss'

type AppFormSliderProps = {
  value: number | number[]
  onChange: (value: number | Range, event: SyntheticEvent<Element, Event>) => void
  label: string
  min: number
  max: number
  step: number
  disabled?: boolean
  rangeMode?: boolean
}

const AppFormSlider = (props: AppFormSliderProps) => {
  const {
    value,
    onChange,
    label,
    min,
    max,
    step,
    disabled = false,
    rangeMode = false,
  } = props

  return (
    <>
      <AppParagraph text={label} size="small" marginBottom="small" />
      <div className={styles.sliderContainer}>
        {rangeMode ? (
          <RangeSlider
            graduated
            tooltip={false}
            renderMark={n => <Mark n={n} values={value as number[]} />}
            step={1}
            min={min}
            max={max}
            value={[value[0], value[1]]}
            onChange={onChange}
            disabled={disabled}
            progress
            constraint={([start, end]) => start < end && end > start}
          />
        ) : (
          <Slider
            graduated
            tooltip={false}
            renderMark={n => <Mark n={n} value={value as number} />}
            step={step}
            min={min}
            max={max}
            value={value as number}
            onChange={onChange}
            disabled={disabled}
          />
        )}
      </div>
    </>
  )
}

export default AppFormSlider
