// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t4HFgQDrfvy51WM0qiTA{width:100%}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppPlaceHolder/app-place-holder.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".appPlaceHolder {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appPlaceHolder": `t4HFgQDrfvy51WM0qiTA`
};
export default ___CSS_LOADER_EXPORT___;
