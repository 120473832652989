import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import qs from 'qs'

import { DEFAULT_THEME, Theme } from 'client/utils'

import {
  AppDivider,
  AppParagraph,
  AppSegment,
  AppFluidMenu,
  AppFormSelect,
} from 'client/ui-building-blocks'

import ButtonPreview from './ButtonPreview/button-preview'
import ChipPreview from './ChipPreview/chip-preview'
import IconsPreview from './IconsPreview/icons-preview'
import HeaderPreview from './HeaderPreview/header-preview'
import ParagraphPreview from './ParagraphPreview/paragraph-preview'
import GroupPreview from './GroupPreview/group-preview'
import FormPreview from './FormPreview/form-preview'
import LoaderPreview from './LoaderPreview/loader-preview'
import MessageBoxPreview from './MessageBoxPreview/message-box-preview'

import styles from './app-ui-preview.module.scss'

const TABS = [
  'button',
  'chip',
  'icons',
  'header',
  'paragraph',
  'group',
  'form',
  'loader',
  'messageBox',
]

const AppUiPreview = () => {
  const { search } = useLocation()
  const push = useNavigate()

  const queryParams = qs.parse(search.slice(1))
  let themeParam = queryParams.theme
  let tabParam = queryParams.tab

  if (![Theme.light, Theme.dark].includes(themeParam as Theme)) {
    themeParam = DEFAULT_THEME
  }

  if (!TABS.includes(tabParam as string)) {
    tabParam = TABS[0]
  }

  const [theme, setTheme] = useState<Theme>(themeParam as Theme)
  const [tab, setTab] = useState<string>(tabParam as string)

  const onTabChange = (i: number): boolean => {
    setTab(TABS[i])
    return true
  }

  const onThemeChange = ({ target: { value } }) => {
    setTheme(value)
  }

  const menuItems = TABS.map(t => ({
    label: t[0].toUpperCase() + t.slice(1),
    onClick: onTabChange,
  }))

  useEffect(() => {
    const html = document.querySelector('html')
    html.dataset.theme = theme
    push(`/ui?theme=${theme}&tab=${tab}`)
  }, [push, theme, tab])

  return (
    <div className={styles.appUiPreview}>
      <AppDivider />
      <AppSegment
        headerProps={{
          text: 'UI Building Blocks',
          iconProps: {
            name: 'settings',
          },
          size: 'large',
          marginBottom: 'small',
        }}
        maxWidth="large"
        centered
      >
        <AppParagraph
          text="UI wrapper components around Semantic UI React to create a bridge between application components and external UI framework and thus keep all UI logic in a separated place."
          marginBottom="small"
        />
        <AppFormSelect
          label="Theme"
          value={theme}
          options={[
            { value: Theme.light, text: 'Light' },
            { value: Theme.dark, text: 'Dark' },
          ]}
          onChange={onThemeChange}
          position="right"
        />
        <AppDivider />
        <AppFluidMenu menuItems={menuItems} defaultItemIndex={TABS.indexOf(tab)}>
          <div>
            <ButtonPreview />
          </div>
          <div>
            <ChipPreview />
          </div>
          <div>
            <IconsPreview />
          </div>
          <div>
            <HeaderPreview theme={theme} />
          </div>
          <div>
            <ParagraphPreview theme={theme} />
          </div>
          <div>
            <GroupPreview />
          </div>
          <div>
            <FormPreview />
          </div>
          <div>
            <LoaderPreview />
          </div>
          <div>
            <MessageBoxPreview />
          </div>
        </AppFluidMenu>
      </AppSegment>
    </div>
  )
}

export default AppUiPreview
