import { ReactNode } from 'react'
import classNames from 'classnames'

import { Modal } from 'client/semantic-imports'
import { AppDivider, AppLoader } from 'client/ui-building-blocks'

import styles from './app-modal-dialog.module.scss'

type Props = {
  id?: string
  open: boolean
  loading?: boolean
  size?: 'tiny' | 'large'
  onClose: () => void
  Header?: ReactNode
  Content: ReactNode
  Actions?: ReactNode
}

const AppModalDialog = ({
  id,
  open,
  loading = false,
  size = 'tiny',
  onClose,
  Header,
  Content,
  Actions,
}: Props) => {
  return (
    <Modal
      className={classNames(
        styles.appModalDialog,
        styles[size],
        size === 'large' && styles.enterAnimation
      )}
      data-testid="app-modal-dialog"
      id={id}
      open={open}
      size={size}
      onClose={onClose}
      centered
    >
      <AppLoader active={loading}>
        <div className={styles.container}>
          {Header && <div className={styles.header}>{Header}</div>}
          <div id="app-modal-content" className={styles.content}>
            {Content}
            <AppDivider />
          </div>
          {Actions && <div className={styles.actions}>{Actions}</div>}
        </div>
      </AppLoader>
    </Modal>
  )
}

export default AppModalDialog
