import { AppDivider, AppButton, AppGroup, AppSegment } from 'client/ui-building-blocks'

const ButtonPreview = () => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Variant', marginBottom: 'small' }}>
        <AppGroup>
          <AppButton text="Filled" variant="filled" />
          <AppButton text="Outlined" variant="outlined" />
          <AppButton text="Blank" variant="blank" />
          <AppButton text="Warning" variant="warning" />
          <AppButton text="Dark" variant="dark" />
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Disabled', marginBottom: 'small' }}>
        <AppGroup>
          <AppButton text="Filled" variant="filled" disabled />
          <AppButton text="Outlined" variant="outlined" disabled />
          <AppButton text="Blank" variant="blank" disabled />
          <AppButton text="Warning" variant="warning" disabled />
          <AppButton text="Dark" variant="dark" disabled />
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Loading', marginBottom: 'small' }}>
        <AppButton text="Fetch" loading />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Size', marginBottom: 'small' }}>
        <AppGroup>
          <AppButton text="Small" size="small" />
          <AppButton text="Medium" size="medium" />
          <AppButton text="Large" size="large" />
        </AppGroup>
        <AppDivider />
        <AppButton text="Full width" fullWidth />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Icon', marginBottom: 'small' }}>
        <AppGroup>
          <AppButton text="Approved" iconName="checked" />
          <AppButton text="Share" iconName="share" variant="outlined" />
          <AppButton iconName="forward" variant="blank" />
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Size + icon', marginBottom: 'small' }}>
        <AppGroup>
          <AppButton text="Small" size="small" iconName="checked" />
          <AppButton text="Medium" size="medium" iconName="settings" />
          <AppButton text="Large" size="large" iconName="follow" />
        </AppGroup>
        <AppDivider />
        <AppGroup>
          <AppButton text="Small" size="small" iconName="close" iconPosition="right" />
          <AppButton text="Medium" size="medium" iconName="next" iconPosition="right" />
          <AppButton text="Large" size="large" iconName="write" iconPosition="right" />
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Link', marginBottom: 'small' }}>
        <AppButton
          text="Google"
          variant="outlined"
          iconName="search"
          href="https://google.com"
        />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Alignment', marginBottom: 'small' }}>
        <AppButton text="Left aligned" />
        <AppDivider />
        <AppButton text="Centered" position="center" />
        <AppDivider />
        <AppButton text="Right aligned" position="right" />
        <AppDivider />
        <AppButton text="Text left aligned" fullWidth textPosition="left" />
        <AppDivider />
        <AppButton text="Text right aligned" fullWidth textPosition="right" />
      </AppSegment>
    </>
  )
}

export default ButtonPreview
