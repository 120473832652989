import { ChangeEvent } from 'react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types'
import { useTranslation } from 'react-i18next'

import { DATE_PICKER_LOCALES, DEFAULT_THEME } from 'client/utils'
import { getLocalLastUserConfig } from 'client/services'
import { Form } from 'client/semantic-imports'
import { AppFormFieldLabel } from 'client/ui-building-blocks'

import './app-date-picker.scss'

type AppDatePickerProps = {
  label: string
  minDate: Date
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: string
}

const AppDatePicker = (props: AppDatePickerProps) => {
  const { t, i18n } = useTranslation()

  const { label, minDate, value, onChange, disabled, error } = props

  const currentTheme = getLocalLastUserConfig().theme || DEFAULT_THEME

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    data: SemanticDatepickerProps
  ) => {
    const date = data.value as Date
    if (date) {
      date.setHours(-new Date(date).getTimezoneOffset() / 60)
      e.target.value = date.toString()
    } else {
      e.target.value = null
    }
    onChange(e)
  }

  return (
    <div className="appDatePicker">
      <Form.Field
        control={() => (
          <SemanticDatepicker
            value={value ? new Date(value) : null}
            onChange={handleChange}
            size="big"
            format={t('common.date.format')}
            placeholder={t('common.date.placeholder')}
            minDate={minDate}
            maxDate={new Date()}
            autoComplete="off"
            locale={DATE_PICKER_LOCALES[i18n.language]}
            showToday={false}
            inverted={currentTheme === 'light'}
            clearIcon={null}
            icon={null}
            keepOpenOnClear
            keepOpenOnSelect
            disabled={disabled}
          />
        )}
        label={<AppFormFieldLabel label={label} error={error} />}
        disabled={disabled}
      />
    </div>
  )
}

export default AppDatePicker
