import keys from 'lodash/keys'

import {
  AppIcon,
  APP_ICONS,
  IconName,
  AppPopup,
  AppSegment,
  AppGroup,
} from 'client/ui-building-blocks'

const IconsPreview = () => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Small', marginBottom: 'small' }}>
        <AppGroup gapSize="medium">
          {keys(APP_ICONS).map((iconName: IconName) => (
            <AppPopup key={iconName} text={iconName}>
              <AppIcon name={iconName} size="small" />
            </AppPopup>
          ))}
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Medium', marginBottom: 'small' }}>
        <AppGroup gapSize="medium">
          {keys(APP_ICONS).map((iconName: IconName) => (
            <AppPopup key={iconName} text={iconName}>
              <AppIcon name={iconName} size="medium" />
            </AppPopup>
          ))}
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Large', marginBottom: 'small' }}>
        <AppGroup gapSize="medium">
          {keys(APP_ICONS).map((iconName: IconName) => (
            <AppPopup key={iconName} text={iconName}>
              <AppIcon name={iconName} size="large" />
            </AppPopup>
          ))}
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Extra large', marginBottom: 'small' }}>
        <AppGroup gapSize="medium">
          {keys(APP_ICONS).map((iconName: IconName) => (
            <AppPopup key={iconName} text={iconName}>
              <AppIcon name={iconName} size="xlarge" />
            </AppPopup>
          ))}
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Double extra large', marginBottom: 'small' }}>
        <AppGroup gapSize="medium">
          {keys(APP_ICONS).map((iconName: IconName) => (
            <AppPopup key={iconName} text={iconName}>
              <AppIcon name={iconName} size="xxlarge" />
            </AppPopup>
          ))}
        </AppGroup>
      </AppSegment>
    </>
  )
}

export default IconsPreview
