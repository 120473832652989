import { ToastContainer } from 'react-toastify'

import { useModalDialogContext } from 'client/hooks'

import './app-snack-bar.scss'

const AppSnackBar = () => {
  const { toggleQuestionModal } = useModalDialogContext()

  return (
    <div
      className="appSnackBar"
      onClick={() => {
        toggleQuestionModal(false)
      }}
    >
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar
        closeButton={false}
        closeOnClick
      />
    </div>
  )
}

export default AppSnackBar
