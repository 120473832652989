import AppParagraph from '../AppParagraph/app-paragraph'

type Props = {
  n: number
  value?: number
  values?: number[]
}

const Mark = ({ n, value, values }: Props) => {
  const isSelected = values?.includes(n) || value === n

  const singleDigit = n < 10

  return (
    <div
      style={{
        position: 'relative',
        left: singleDigit ? '-2.5px' : '-4.5px',
        fontSize: '19px',
        fontWeight: isSelected ? 'bold' : 'normal',
      }}
    >
      <AppParagraph text={singleDigit ? ' ' + n : n.toString()} size="small" />
    </div>
  )
}

export default Mark
