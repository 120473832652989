// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UYb5Cq1SakmZLgb05hvZ{padding:0 var(--gap-medium)}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppUiPreview/app-ui-preview.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA","sourcesContent":[".appUiPreview {\n  padding: 0 var(--gap-medium);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appUiPreview": `UYb5Cq1SakmZLgb05hvZ`
};
export default ___CSS_LOADER_EXPORT___;
