import { AppButton, AppDivider, AppGroup, AppSegment } from 'client/ui-building-blocks'

const GroupPreview = () => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Horizontal', marginBottom: 'small' }}>
        <AppGroup>
          <AppButton text="Button 1" />
          <AppButton text="Button 2" />
          <AppButton text="Button 3" />
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Vertical', marginBottom: 'small' }}>
        <AppGroup alignment="vertical">
          <AppButton text="Button 1" />
          <AppButton text="Button 2" />
          <AppButton text="Button 3" />
        </AppGroup>
      </AppSegment>
      <AppSegment
        headerProps={{ text: 'Horizontal and centered', marginBottom: 'small' }}
      >
        <AppGroup centered>
          <AppButton text="Button 1" />
          <AppButton text="Button 2" />
          <AppButton text="Button 3" />
        </AppGroup>
        <AppDivider />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Vertical and centered', marginBottom: 'small' }}>
        <AppGroup alignment="vertical" centered>
          <AppButton text="Button 1" />
          <AppButton text="Button 2" />
          <AppButton text="Button 3" />
        </AppGroup>
        <AppDivider />
      </AppSegment>
      <AppSegment
        headerProps={{ text: 'Horizontal and full width', marginBottom: 'small' }}
      >
        <AppGroup fullWidth>
          <AppButton text="Button 1" />
          <AppButton text="Button 2" />
          <AppButton text="Button 3" />
        </AppGroup>
      </AppSegment>
      <AppSegment
        headerProps={{ text: 'Vertical and full width', marginBottom: 'small' }}
      >
        <AppGroup alignment="vertical" fullWidth>
          <AppButton text="Button 1" />
          <AppButton text="Button 2" />
          <AppButton text="Button 3" />
        </AppGroup>
      </AppSegment>
    </>
  )
}

export default GroupPreview
