import { AppGroup, AppChip, AppSegment } from 'client/ui-building-blocks'

const ChipPreview = () => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Variant', marginBottom: 'small' }}>
        <AppGroup centered>
          <AppChip text="Filled" variant="filled" />
          <AppChip text="Outlined" variant="outlined" />
          <AppChip text="Blank" variant="blank" />
          <AppChip text="Warning" variant="warning" />
          <AppChip text="Dark" variant="dark" />
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Size', marginBottom: 'small' }}>
        <AppGroup centered>
          <AppChip size="small" text="Small" />
          <AppChip size="medium" text="Medium" />
          <AppChip size="large" text="Large" />
        </AppGroup>
      </AppSegment>
      <AppSegment headerProps={{ text: 'Alignment', marginBottom: 'small' }}>
        <AppChip text="I am in center" centered />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Icon', marginBottom: 'small' }}>
        <AppChip text="You can close me" iconName="close" onIconClick={() => null} />
      </AppSegment>
    </>
  )
}

export default ChipPreview
