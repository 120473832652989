import { createContext, useContext, useState, ReactNode } from 'react'

type TModalDialogContext = {
  showNoAccessModal: boolean
  toggleNoAccessModal: (open: boolean) => void
  showDeleteQuestionModal: boolean
  toggleDeleteQuestionModal: (open: boolean) => void
  showTerminateQuestionModal: boolean
  toggleTerminateQuestionModal: (open: boolean) => void
  showReportQuestionModal: boolean
  toggleReportQuestionModal: (open: boolean) => void
  showDeleteAccountModal: boolean
  toggleDeleteAccountModal: (open: boolean) => void
  showQuestionModal: boolean
  toggleQuestionModal: (open: boolean) => void
  closeAllModals: () => void
}

const ModalDialogContext = createContext<TModalDialogContext>({} as TModalDialogContext)

type Props = {
  children: ReactNode
  value?: {
    showNoAccessModal: boolean
  }
}

export const ModalDialogContextProvider = ({ children, value }: Props) => {
  const [showNoAccessModal, toggleNoAccessModal] = useState<boolean>(
    value?.showNoAccessModal || false
  )
  const [showDeleteQuestionModal, toggleDeleteQuestionModal] = useState<boolean>(false)
  const [showTerminateQuestionModal, toggleTerminateQuestionModal] =
    useState<boolean>(false)
  const [showReportQuestionModal, toggleReportQuestionModal] = useState<boolean>(false)
  const [showDeleteAccountModal, toggleDeleteAccountModal] = useState<boolean>(false)
  const [showQuestionModal, toggleQuestionModal] = useState<boolean>(false)

  const closeAllModals = () => {
    toggleNoAccessModal(false)
    toggleDeleteQuestionModal(false)
    toggleTerminateQuestionModal(false)
    toggleReportQuestionModal(false)
    toggleDeleteAccountModal(false)
    toggleQuestionModal(false)
  }

  return (
    <ModalDialogContext.Provider
      value={{
        showNoAccessModal,
        toggleNoAccessModal,
        showDeleteAccountModal,
        toggleDeleteAccountModal,
        showTerminateQuestionModal,
        toggleTerminateQuestionModal,
        showReportQuestionModal,
        toggleReportQuestionModal,
        showDeleteQuestionModal,
        toggleDeleteQuestionModal,
        showQuestionModal,
        toggleQuestionModal,
        closeAllModals,
      }}
    >
      {children}
    </ModalDialogContext.Provider>
  )
}

export const useModalDialogContext = () => {
  return useContext(ModalDialogContext)
}
