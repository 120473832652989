// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ux8Fc9rox6WgfQxULGo2{margin:0 !important}.Ux8Fc9rox6WgfQxULGo2 .beaySlfTlflqGkDERKLb{display:flex;align-items:center !important}.Ux8Fc9rox6WgfQxULGo2 .beaySlfTlflqGkDERKLb .Nbv3b_l6C9kfSAerzSUm{padding-right:10px}.Ux8Fc9rox6WgfQxULGo2 .beaySlfTlflqGkDERKLb .Nbv3b_l6C9kfSAerzSUm.izgHwskHdq9j4KezyHwB{padding-left:10px}.Ux8Fc9rox6WgfQxULGo2.I6QAi4vrbKoHfy6Pv0YA .beaySlfTlflqGkDERKLb{justify-content:center}.Ux8Fc9rox6WgfQxULGo2.I6QAi4vrbKoHfy6Pv0YA .beaySlfTlflqGkDERKLb .V4e4ZqYusUvQJB6P7r14{text-align:center}.Ux8Fc9rox6WgfQxULGo2:not(.I6QAi4vrbKoHfy6Pv0YA) .beaySlfTlflqGkDERKLb>div:last-child{flex:1}.Ux8Fc9rox6WgfQxULGo2:not(.I6QAi4vrbKoHfy6Pv0YA) .beaySlfTlflqGkDERKLb .hMQnfF8NcnuoAnxW6uw7{display:flex;justify-content:flex-end}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppHeader/app-header.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAEA,4CACE,YAAA,CACA,6BAAA,CAEA,kEACE,kBAAA,CAEA,uFACE,iBAAA,CAMJ,iEACE,sBAAA,CAEA,uFACE,iBAAA,CAOF,sFACE,MAAA,CAGF,6FACE,YAAA,CACA,wBAAA","sourcesContent":[".appHeader {\n  margin: 0 !important;\n\n  .headerContent {\n    display: flex;\n    align-items: center !important;\n\n    .iconContainer {\n      padding-right: 10px;\n\n      &.right {\n        padding-left: 10px;\n      }\n    }\n  }\n\n  &.centered {\n    .headerContent {\n      justify-content: center;\n\n      .paragraphContainer {\n        text-align: center;\n      }\n    }\n  }\n\n  &:not(.centered) {\n    .headerContent {\n      > div:last-child {\n        flex: 1;\n      }\n\n      .rightSlot {\n        display: flex;\n        justify-content: flex-end;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appHeader": `Ux8Fc9rox6WgfQxULGo2`,
	"headerContent": `beaySlfTlflqGkDERKLb`,
	"iconContainer": `Nbv3b_l6C9kfSAerzSUm`,
	"right": `izgHwskHdq9j4KezyHwB`,
	"centered": `I6QAi4vrbKoHfy6Pv0YA`,
	"paragraphContainer": `V4e4ZqYusUvQJB6P7r14`,
	"rightSlot": `hMQnfF8NcnuoAnxW6uw7`
};
export default ___CSS_LOADER_EXPORT___;
