// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhgsnPpCemfP5EMqiDo0{position:relative;height:400px;border:2px dashed var(--border)}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppUiPreview/LoaderPreview/loader-preview.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,+BAAA","sourcesContent":[".loaderContainer {\n  position: relative;\n  height: 400px;\n  border: 2px dashed var(--border);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `PhgsnPpCemfP5EMqiDo0`
};
export default ___CSS_LOADER_EXPORT___;
