import { ReactNode } from 'react'
import classNames from 'classnames'

import { GridLayoutVariant } from '../types'

import styles from './app-grid-layout.module.scss'

type AppGridLayoutProps = {
  children: ReactNode[]
  variant?: GridLayoutVariant
}

const AppGridLayout = (props: AppGridLayoutProps) => {
  const { children, variant = 'relaxed' } = props

  return (
    <div className={classNames(styles.appGridLayout, styles[variant])}>{children}</div>
  )
}

export default AppGridLayout
