import { useMemo, CSSProperties } from 'react'

import { Dropdown } from 'client/semantic-imports'
import { AppHeader } from 'client/ui-building-blocks'

import styles from './app-context-menu.module.scss'
import './app-context-menu.scss'

type Props = {
  label?: string
  items: {
    label: string
    onClick: () => void
    hide?: boolean
    disabled?: boolean
  }[]
  isLoading?: boolean
  isDisabled?: boolean
  style?: CSSProperties
  pointing?:
    | 'top left'
    | 'left'
    | 'right'
    | 'bottom'
    | 'top'
    | 'top right'
    | 'bottom left'
    | 'bottom right'
}

const AppContextMenu = ({
  label,
  items,
  isLoading = false,
  isDisabled = false,
  style = {},
  pointing = 'top left',
}: Props) => {
  return useMemo(() => {
    const disabled =
      isLoading || isDisabled || !items.length || !items.some(item => !item.hide)

    return (
      <div className="appContextMenu">
        <Dropdown
          data-testid="app-context-menu"
          compact
          button
          icon={label ? 'dropdown' : 'ellipsis horizontal'}
          text={label}
          pointing={pointing}
          loading={isLoading}
          disabled={disabled}
          style={style}
          className={styles.dropdown}
        >
          <Dropdown.Menu className={styles.menu}>
            {items
              .filter(item => !item.hide)
              .map((item, i) => (
                <Dropdown.Item
                  key={i}
                  data-testid="app-context-menu-item"
                  disabled={item.disabled}
                  onClick={item.onClick}
                >
                  <AppHeader text={item.label} size="small" color="inverted" />
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    )
  }, [items, isLoading, isDisabled, label, pointing, style])
}

export default AppContextMenu
