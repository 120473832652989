import { useNavigate, useLocation } from 'react-router-dom'
import keys from 'lodash/keys'
import qs from 'qs'

type NavigateToArgs = {
  path: string
  query?: Record<string, string>
  replace?: boolean
  preventScroll?: boolean
}

const PATHS = [
  '/',
  '/auth',
  '/home',
  '/browse',
  '/random',
  '/create',
  '/question',
  '/profile',
  '/settings',
  '/about',
]

const ALLOWED_SEARCH_PARAMS = {
  _id: ['/profile', '/question', '/create'],
  questionId: ['/home', '/browse'],
}

export default () => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const navigateTo = ({
    path,
    query,
    replace = false,
    preventScroll = false,
  }: NavigateToArgs) => {
    let finalPath = path
    if (query) finalPath += `?${qs.stringify(query)}`

    if (finalPath !== pathname + search) {
      navigate(finalPath, { replace })

      if (!preventScroll) {
        window.scroll(0, 0)
      }
    }
  }

  const goToCleanRoute = (preventScroll?: boolean) => {
    navigateTo({ path: pathname, preventScroll })
  }

  const checkQueryParams = (path: string, search: string): boolean => {
    const params = qs.parse(search.substring(1))
    const paramsKeys = keys(params)
    const allowedParamsKeys = keys(ALLOWED_SEARCH_PARAMS)

    const query = {}
    let replaceUrl = false

    paramsKeys.forEach(k => {
      const allowed =
        allowedParamsKeys.includes(k) && ALLOWED_SEARCH_PARAMS[k].includes(path)

      if (allowed) {
        query[k] = params[k]
      } else {
        replaceUrl = true
      }
    })

    if (replaceUrl) {
      navigateTo({ path, query, replace: true, preventScroll: true })
    }

    return !replaceUrl
  }

  return {
    navigateTo,
    goToCleanRoute,
    checkQueryParams,
    PATHS,
  }
}
