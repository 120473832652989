// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VgsoDwCE8hA1cgJWQulu{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.BleCtQiw_VrswLkHL6J2{position:relative;left:50%;transform:translateX(-50%)}.YJUT1Jm_DnWQlshgikYv{display:flex;justify-content:flex-end}.WQNKrOScc9wdvBQBHGO4{text-align:center}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/classes.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CAGF,sBACE,iBAAA,CACA,QAAA,CACA,0BAAA,CAGF,sBACE,YAAA,CACA,wBAAA,CAGF,sBACE,iBAAA","sourcesContent":[".absolute {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.center {\n  position: relative;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.right {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.textCenter {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"absolute": `VgsoDwCE8hA1cgJWQulu`,
	"center": `BleCtQiw_VrswLkHL6J2`,
	"right": `YJUT1Jm_DnWQlshgikYv`,
	"textCenter": `WQNKrOScc9wdvBQBHGO4`
};
export default ___CSS_LOADER_EXPORT___;
