// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d4n5YhAtHFVet0bpDnu8{padding:0 7px;min-height:50px}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppFormSlider/app-form-slider.module.scss"],"names":[],"mappings":"AAAA,sBACE,aAAA,CACA,eAAA","sourcesContent":[".sliderContainer {\n  padding: 0 7px;\n  min-height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sliderContainer": `d4n5YhAtHFVet0bpDnu8`
};
export default ___CSS_LOADER_EXPORT___;
