// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R0td6cBUlFQW2NAJJ2cd .HipwMLy2W0YUB04RUOQ8{display:flex;cursor:pointer}.R0td6cBUlFQW2NAJJ2cd .HipwMLy2W0YUB04RUOQ8>div:last-child{margin-left:15px}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppFormCheckBox/app-form-check-box.module.scss"],"names":[],"mappings":"AACE,4CACE,YAAA,CACA,cAAA,CAGF,2DACE,gBAAA","sourcesContent":[".appFormCheckBox {\n  .optionContainer {\n    display: flex;\n    cursor: pointer;\n  }\n\n  .optionContainer > div:last-child {\n    margin-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appFormCheckBox": `R0td6cBUlFQW2NAJJ2cd`,
	"optionContainer": `HipwMLy2W0YUB04RUOQ8`
};
export default ___CSS_LOADER_EXPORT___;
