import { useTranslation } from 'react-i18next'

import {
  AppDivider,
  AppFormSelect,
  AppGroup,
  AppButton,
  AppGridLayout,
} from 'client/ui-building-blocks'

type Props = {
  data?: {
    count: number
    data: unknown[]
  }
  isDisabled: boolean
  pageSize: number
  activePage: number
  onPageChange: (pageNo: number) => void
}

const AppNavigation = ({
  data = { count: 0, data: [] },
  isDisabled,
  pageSize,
  activePage,
  onPageChange,
}: Props) => {
  const { t } = useTranslation()

  const totalPages = Math.ceil(data.count / pageSize) || 1

  if (activePage === undefined || !onPageChange || totalPages <= 1) {
    return null
  }

  return (
    <div data-testid="app-navigation">
      <AppGridLayout>
        <AppFormSelect
          options={Array.from({ length: totalPages }).map((value, index) => ({
            value: String(index + 1),
            text: t('common.list.pagination.pageNo', { pageNo: index + 1 }),
          }))}
          value={String(activePage)}
          onChange={e => onPageChange(Number(e.target.value))}
        />
        <AppGroup gapSize="xsmall">
          <AppButton
            variant="outlined"
            size="small"
            iconName="moveToFirst"
            onClick={() => onPageChange(1)}
            disabled={isDisabled || activePage === 1}
          />
          <AppButton
            variant="outlined"
            size="small"
            iconName="prev"
            onClick={() => onPageChange(activePage - 1)}
            disabled={isDisabled || activePage === 1}
          />
          <AppButton
            variant="outlined"
            size="small"
            iconName="next"
            onClick={() => onPageChange(activePage + 1)}
            disabled={isDisabled || activePage === totalPages}
          />
          <AppButton
            variant="outlined"
            size="small"
            iconName="moveToLast"
            onClick={() => onPageChange(totalPages)}
            disabled={isDisabled || activePage === totalPages}
          />
        </AppGroup>
      </AppGridLayout>
      <AppDivider size="large" />
    </div>
  )
}

export default AppNavigation
