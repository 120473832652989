// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xF_1DCwUeCQUWbeinKTn{position:fixed;top:0;left:0;width:100%;height:100%;z-index:2;background-color:rgba(0,0,0,.75)}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppDimmer/app-dimmer.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CACA,gCAAA","sourcesContent":[".appDimmer {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 2;\n  background-color: rgba(0, 0, 0, 0.75);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appDimmer": `xF_1DCwUeCQUWbeinKTn`
};
export default ___CSS_LOADER_EXPORT___;
