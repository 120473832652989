import { ReactElement, cloneElement } from 'react'
import classNames from 'classnames'

import { Alignment, Size } from '../types'

import styles from './app-group.module.scss'

type AppGroupProps = {
  children: ReactElement[]
  alignment?: Alignment
  fullWidth?: boolean | 'mobile'
  gapSize?: Size
  centered?: boolean
}

const AppGroup = (props: AppGroupProps) => {
  const {
    children,
    alignment = 'horizontal',
    fullWidth = false,
    gapSize = 'small',
    centered = false,
  } = props

  return (
    <div
      className={classNames(
        styles.appGroup,
        styles[alignment],
        styles[gapSize],
        fullWidth
          ? fullWidth === 'mobile'
            ? styles.fullWidthMobile
            : styles.fullWidth
          : undefined,
        centered && styles.centered
      )}
    >
      {children.map((child, i) =>
        child ? <div key={i}>{cloneElement(child, { fullWidth })}</div> : null
      )}
    </div>
  )
}

export default AppGroup
