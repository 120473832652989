// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChCvkdVcbEiA5AyMwowY.mPG6gA8NHxtoYgnI8RZP{cursor:pointer}.ChCvkdVcbEiA5AyMwowY.b4R7pLb60i45fz2iMRaw{box-shadow:#000 0 0 8px 0}.ChCvkdVcbEiA5AyMwowY.zMczJAYxFg0buGV6ISiN{border-radius:50%}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppImage/app-image.module.scss"],"names":[],"mappings":"AACE,2CACE,cAAA,CAGF,2CACE,yBAAA,CAGF,2CACE,iBAAA","sourcesContent":[".appImage {\n  &.pointer {\n    cursor: pointer;\n  }\n\n  &.shadow {\n    box-shadow: black 0 0 8px 0;\n  }\n\n  &.rounded {\n    border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appImage": `ChCvkdVcbEiA5AyMwowY`,
	"pointer": `mPG6gA8NHxtoYgnI8RZP`,
	"shadow": `b4R7pLb60i45fz2iMRaw`,
	"rounded": `zMczJAYxFg0buGV6ISiN`
};
export default ___CSS_LOADER_EXPORT___;
