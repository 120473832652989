import { MouseEvent } from 'react'
import classNames from 'classnames'

import styles from './app-image.module.scss'
import classes from '../classes.module.scss'

type AppImageProps = {
  src: string
  centered?: boolean
  width?: string
  height?: string
  alt?: string
  shadow?: boolean
  rounded?: boolean
  loading?: boolean
  onClick?: (e: MouseEvent<HTMLImageElement>) => void
}

const AppImage = (props: AppImageProps) => {
  const {
    src,
    centered = false,
    width,
    height,
    alt = '',
    shadow = false,
    rounded = false,
    onClick = null,
  } = props

  return (
    <img
      className={classNames(
        styles.appImage,
        centered && classes.center,
        onClick && styles.pointer,
        shadow && styles.shadow,
        rounded && styles.rounded
      )}
      src={src}
      width={width}
      height={height}
      alt={alt}
      onClick={onClick}
    />
  )
}

export default AppImage
