import classNames from 'classnames'

import { Icon, SemanticICONS } from 'client/semantic-imports'

import { IconName, APP_ICONS, Size, AppPopup, IconColor } from '..'

import styles from './app-icon.module.scss'

export type AppIconProps = {
  name: IconName
  size?: Size
  color?: IconColor
  disabled?: boolean
  inverted?: boolean
  pointer?: boolean
  popupText?: string
  onClick?: () => void
}

const AppIcon = (props: AppIconProps) => {
  const {
    name,
    size = 'medium',
    color,
    disabled = false,
    inverted = false,
    pointer = false,
    popupText = '',
    onClick = null,
  } = props

  const IconComponent = () => (
    <Icon
      data-testid="app-icon"
      className={classNames(
        styles.appIcon,
        styles[size],
        disabled && styles.disabled,
        pointer && !disabled && styles.pointer,
        !color && styles.defaultColor
      )}
      name={APP_ICONS[name] as SemanticICONS}
      onClick={!disabled ? onClick : null}
      color={color}
      inverted={inverted}
    />
  )

  if (!popupText) {
    return <IconComponent />
  } else {
    return (
      <AppPopup text={popupText}>
        <IconComponent />
      </AppPopup>
    )
  }
}

export default AppIcon
