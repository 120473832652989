import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AppButton,
  AppDivider,
  AppLoader,
  AppGridLayout,
  AppParagraph,
  AppNavigation,
  GridLayoutVariant,
  AppSegment,
} from '..'

import styles from './app-list.module.scss'

type Props = {
  data?: { count: number; data: unknown[] }
  renderItem: (item: unknown, i: number) => ReactNode
  title?: string
  isLoading?: boolean
  isFetching?: boolean
  noItemsMsg?: string
  pageSize?: number
  activePage?: number
  gridLayoutVariant?: GridLayoutVariant
  onPageChange?: (pageNo: number) => void
}

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const AppList = ({
  data = { count: 0, data: [] },
  renderItem,
  title,
  isLoading,
  isFetching,
  noItemsMsg,
  pageSize,
  activePage,
  gridLayoutVariant = 'relaxed',
  onPageChange,
}: Props) => {
  const { t } = useTranslation()

  const displayNavigation = onPageChange && activePage !== undefined
  const displayNoItemsInfo = !isLoading && !isFetching && !data.data.length
  const displayScrollTopBtn = pageSize !== undefined && data?.data?.length >= pageSize / 2
  const displayLoader = isLoading || (isFetching && !data?.data.length)

  return (
    <div data-testid="app-list" className={styles.appList}>
      <AppSegment headerProps={{ text: title }}>
        {displayNavigation && (
          <AppNavigation
            data={data}
            isDisabled={isLoading || isFetching}
            pageSize={pageSize}
            activePage={activePage}
            onPageChange={onPageChange}
          />
        )}
        <AppLoader active={displayLoader}>
          <AppGridLayout variant={gridLayoutVariant}>
            {data.data?.map(renderItem)}
          </AppGridLayout>
          {displayNoItemsInfo && (
            <AppParagraph
              text={noItemsMsg || t('common.info.nothingToDisplay')}
              size="medium"
              centered
            />
          )}
          {displayScrollTopBtn && (
            <>
              <AppDivider size="large" />
              <AppButton
                variant="outlined"
                iconName="scrollTop"
                size="small"
                position="center"
                onClick={scrollToTop}
                dataTestId="scroll-top-btn"
                aria-label="Scroll top button"
              />
            </>
          )}
        </AppLoader>
      </AppSegment>
    </div>
  )
}

export default AppList
