// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y3qZ6ewB2BGe7WZJMHzn{background-color:var(--bg-inverted) !important;color:var(--text-inverted) !important}.Y3qZ6ewB2BGe7WZJMHzn::before{background-color:var(--bg-inverted) !important}.jrUehyOsqcNwLZ6MKSpY{display:table}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppPopup/app-popup.module.scss"],"names":[],"mappings":"AAAA,sBACE,8CAAA,CACA,qCAAA,CAGF,8BACE,8CAAA,CAGF,sBACE,aAAA","sourcesContent":[".appPopup {\n  background-color: var(--bg-inverted) !important;\n  color: var(--text-inverted) !important;\n}\n\n.appPopup::before {\n  background-color: var(--bg-inverted) !important;\n}\n\n.trigger {\n  display: table;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appPopup": `Y3qZ6ewB2BGe7WZJMHzn`,
	"trigger": `jrUehyOsqcNwLZ6MKSpY`
};
export default ___CSS_LOADER_EXPORT___;
