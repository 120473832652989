import { MouseEvent, ReactNode } from 'react'
import classNames from 'classnames'

import { Size } from '../types'

import AppHeader, { AppHeaderProps } from '../AppHeader/app-header'
import AppDivider from '../AppDivider/app-divider'

import styles from './app-segment.module.scss'

type AppSegmentProps = {
  children: ReactNode
  maxWidth?: Size
  centered?: boolean
  marginBottom?: Size | boolean
  headerProps?: AppHeaderProps
}

const AppSegment = (props: AppSegmentProps) => {
  const {
    children,
    maxWidth,
    centered = false,
    marginBottom = 'large',
    headerProps,
  } = props

  const onClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.currentTarget.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <div
      className={classNames(
        styles.appSegment,
        maxWidth && styles[maxWidth],
        centered && styles.centered
      )}
    >
      {headerProps && (
        <AppHeader marginBottom="medium" {...headerProps} dividing onClick={onClick} />
      )}
      {children}
      {marginBottom && (
        <AppDivider size={marginBottom === true ? 'large' : marginBottom} />
      )}
    </div>
  )
}

export default AppSegment
