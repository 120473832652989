import { ReactNode } from 'react'

import { Loader } from 'client/semantic-imports'

import styles from './app-loader.module.scss'
import classes from '../classes.module.scss'

type AppLoaderProps = {
  children?: ReactNode
  active?: boolean
  absolute?: boolean
}

const AppLoader = (props: AppLoaderProps) => {
  const { children = null, active = true, absolute = false } = props

  return (
    <span className={absolute ? classes.absolute : undefined}>
      {active ? (
        <Loader
          data-testid="app-loader"
          className={styles.appLoader}
          size="small"
          active
        />
      ) : (
        children
      )}
    </span>
  )
}

export default AppLoader
