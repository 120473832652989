import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppContext } from 'client/hooks'

import styles from './app-dimmer.module.scss'

const AppDimmer = () => {
  const { pathname } = useLocation()
  const { showAppDimmer, setShowAppDimmer } = useAppContext()

  useEffect(() => {
    setShowAppDimmer(false)
  }, [pathname, setShowAppDimmer])

  if (!showAppDimmer) {
    return null
  }

  return <div className={styles.appDimmer} />
}

export default AppDimmer
