import { createContext, useContext, ReactNode } from 'react'

type TEnvContext = {
  awsBucketUrl: string
  recaptchaSiteKey: string
  donationPaymentLink: string
  fullAccountPaymentLink: string
}

const EnvContext = createContext<TEnvContext>({} as TEnvContext)

type Props = {
  children: ReactNode
}

export const EnvContextProvider = ({
  children,
  awsBucketUrl,
  recaptchaSiteKey,
  donationPaymentLink,
  fullAccountPaymentLink,
}: Props & TEnvContext) => {
  return (
    <EnvContext.Provider
      value={{
        awsBucketUrl,
        recaptchaSiteKey,
        donationPaymentLink,
        fullAccountPaymentLink,
      }}
    >
      {children}
    </EnvContext.Provider>
  )
}

export const useEnvContext = () => {
  return useContext(EnvContext)
}
