import { ReactNode } from 'react'

import { Placeholder } from 'client/semantic-imports'
import { getLocalLastUserConfig } from 'client/services'
import { DEFAULT_THEME, Theme } from 'client/utils'

import styles from './app-place-holder.module.scss'

type AppPlaceHolderProps = {
  children: ReactNode
  loading: boolean
  width?: string
  theme?: Theme
}

const AppPlaceHolder = (props: AppPlaceHolderProps) => {
  const { children, loading = false, width = '100%', theme } = props

  if (!loading) {
    return children
  }

  const currentTheme = theme || getLocalLastUserConfig().theme || DEFAULT_THEME

  return (
    <Placeholder
      data-testid="app-placeholder"
      className={styles.appPlaceHolder}
      style={{ width }}
      fluid
      inverted={currentTheme === 'dark'}
    >
      {children}
    </Placeholder>
  )
}

export default AppPlaceHolder
