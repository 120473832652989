// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.k9UC9p_fcCNNpJz0TWJN{position:relative !important;color:var(--text) !important;margin-top:38px !important}.k9UC9p_fcCNNpJz0TWJN::before{border:.2em solid var(--border) !important}.k9UC9p_fcCNNpJz0TWJN::after{border-color:var(--bg-inverted) rgba(0,0,0,0) rgba(0,0,0,0) !important}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppLoader/app-loader.module.scss"],"names":[],"mappings":"AAAA,sBACE,4BAAA,CACA,4BAAA,CACA,0BAAA,CAEA,8BACE,0CAAA,CAGF,6BACE,sEAAA","sourcesContent":[".appLoader {\n  position: relative !important;\n  color: var(--text) !important;\n  margin-top: 38px !important;\n\n  &::before {\n    border: 0.2em solid var(--border) !important;\n  }\n\n  &::after {\n    border-color: var(--bg-inverted) transparent transparent !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appLoader": `k9UC9p_fcCNNpJz0TWJN`
};
export default ___CSS_LOADER_EXPORT___;
