import { ChangeEvent } from 'react'
import { DropdownProps } from 'semantic-ui-react'
import classNames from 'classnames'

import { Form } from 'client/semantic-imports'
import { AppFormFieldLabel } from 'client/ui-building-blocks'
import { useAppContext } from 'client/hooks'

import { Position } from '../types'

import classes from '../classes.module.scss'

type AppFormSelectProps = {
  label?: string
  value: string
  options: { value: string; text: string }[]
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  error?: string
  position?: Position
}

const AppFormSelect = (props: AppFormSelectProps) => {
  const { setShowAppDimmer } = useAppContext()

  const { label = '', value, options, onChange, error = '', position = 'left' } = props

  const onOpen = () => {
    setShowAppDimmer(true)
  }

  const onClose = () => {
    setShowAppDimmer(false)
  }

  return (
    <div
      data-testid="app-form-select"
      className={classNames('appFormSelect', classes[position])}
    >
      <Form.Dropdown
        label={label && <AppFormFieldLabel label={label} error={error} />}
        value={value}
        options={options}
        onChange={(e: ChangeEvent<HTMLInputElement>, data: DropdownProps) => {
          e.target.value = String(data.value)
          onChange(e)
        }}
        onOpen={onOpen}
        onClose={onClose}
        selection
      />
    </div>
  )
}

export default AppFormSelect
