import { Form } from 'client/semantic-imports'
import { AppParagraph } from 'client/ui-building-blocks'

import styles from './app-form-check-box.module.scss'
import './app-form-check-box.scss'

type AppFormCheckBoxProps = {
  currentValues: number[]
  options: { value: number; label: string }[]
  onChange: (newCurrentValues: number[]) => void
  disabled?: boolean
}

const AppFormCheckBox = (props: AppFormCheckBoxProps) => {
  const { currentValues = [], options = [], onChange, disabled = false } = props

  return (
    <div className="appFormCheckBox">
      {options.map(({ value, label }) => {
        const handleChange = (e, data) => {
          const newValues = []
          if (data.checked) {
            newValues.push(...currentValues, value)
          } else {
            newValues.push(...currentValues.filter(v => v !== value))
          }
          onChange(newValues)
        }

        return (
          <Form.Checkbox
            className={styles.appFormCheckBox}
            key={value}
            label={
              <div className={styles.optionContainer}>
                <div>
                  <Form.Checkbox value={value} checked={currentValues.includes(value)} />
                </div>
                <div>
                  <AppParagraph text={label} />
                </div>
              </div>
            }
            checked={currentValues.includes(value)}
            disabled={disabled}
            onChange={handleChange}
          />
        )
      })}
    </div>
  )
}

export default AppFormCheckBox
