import AppParagraph from '../AppParagraph/app-paragraph'

type AppFormFieldLabelProps = {
  label: string
  error: string
}

const AppFormFieldLabel = ({ label, error = '' }: AppFormFieldLabelProps) => {
  return (
    <AppParagraph
      text={!error ? label : `${label} ${error}`}
      color={error ? 'error' : 'primary'}
      size="small"
      marginBottom="xxsmall"
    />
  )
}

export default AppFormFieldLabel
