import { AxiosResponse } from 'axios'

import instance from 'client/aws'

export default (url: string): Promise<AxiosResponse> => {
  return instance(url, {
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  })
}
