import { ReactNode } from 'react'

import { AppDivider, AppParagraph } from 'client/ui-building-blocks'
import { Size } from '../types'

import styles from './app-message-box.module.scss'

type AppMessageBoxProps = {
  size?: Size
  imgNode?: ReactNode
  headerNode?: ReactNode
  paragraphs?: string[]
  buttonNode?: ReactNode
}

const AppMessageBox = (props: AppMessageBoxProps) => {
  const {
    paragraphs = [],
    size = 'medium',
    imgNode = null,
    headerNode = null,
    buttonNode = null,
  } = props

  return (
    <div className={styles.appMessageBox}>
      {imgNode && <div className={styles.imgNode}>{imgNode}</div>}
      <div className={styles.contentContainer}>
        {headerNode && (
          <>
            {headerNode}
            <AppDivider size="small" />
          </>
        )}
        {paragraphs.map((p, i) => (
          <div key={i}>
            <AppParagraph
              text={p}
              size={size}
              marginBottom={i < paragraphs.length - 1 ? 'xxsmall' : false}
            />
          </div>
        ))}
        {buttonNode && (
          <>
            <AppDivider size="small" />
            {buttonNode}
          </>
        )}
      </div>
    </div>
  )
}

export default AppMessageBox
