// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AmujGqVwwGge9uvDkIvn{font-family:var(--font-family),sans-serif !important;margin:0 !important;background-color:rgba(0,0,0,0) !important;color:var(--text) !important;padding:3px 3px 2px 12px !important}.N_OZxCYI9oe_DGak9xFc,.N_OZxCYI9oe_DGak9xFc::after{background-color:var(--bg-inverted) !important}`, "",{"version":3,"sources":["webpack://./src/client/ui-building-blocks/AppContextMenu/app-context-menu.module.scss"],"names":[],"mappings":"AAAA,sBACE,oDAAA,CAEA,mBAAA,CACA,yCAAA,CACA,4BAAA,CACA,mCAAA,CAGF,mDAEE,8CAAA","sourcesContent":[".dropdown {\n  font-family: var(--font-family), sans-serif !important;\n\n  margin: 0 !important;\n  background-color: transparent !important;\n  color: var(--text) !important;\n  padding: 3px 3px 2px 12px !important;\n}\n\n.menu,\n.menu::after {\n  background-color: var(--bg-inverted) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `AmujGqVwwGge9uvDkIvn`,
	"menu": `N_OZxCYI9oe_DGak9xFc`
};
export default ___CSS_LOADER_EXPORT___;
