import { AppParagraph, AppSegment } from 'client/ui-building-blocks'
import { Theme } from 'client/utils'

type Props = {
  theme: Theme
}

const ParagraphPreview = ({ theme }: Props) => {
  return (
    <>
      <AppSegment headerProps={{ text: 'Size', marginBottom: 'small' }}>
        <AppParagraph text="Small" size="small" marginBottom="xsmall" centered />
        <AppParagraph text="Medium" size="medium" marginBottom="xsmall" centered />
        <AppParagraph text="Large" size="large" marginBottom="xsmall" centered />
        <AppParagraph text="Extra large" size="xlarge" marginBottom="xsmall" centered />
        <AppParagraph text="Double extra large" size="xxlarge" centered />
      </AppSegment>
      <AppSegment headerProps={{ text: 'Loading', marginBottom: 'small' }}>
        <AppParagraph
          text="Paragraph text"
          size="small"
          loading
          marginBottom="xsmall"
          theme={theme}
        />
        <AppParagraph
          text="Paragraph text"
          size="medium"
          loading
          marginBottom="xsmall"
          theme={theme}
        />
        <AppParagraph
          text="Paragraph text"
          size="large"
          loading
          marginBottom="xsmall"
          theme={theme}
        />
        <AppParagraph
          text="Paragraph text"
          size="xlarge"
          loading
          marginBottom="xsmall"
          theme={theme}
        />
        <AppParagraph text="Paragraph text" size="xxlarge" loading theme={theme} />
      </AppSegment>
    </>
  )
}

export default ParagraphPreview
