import classNames from 'classnames'

import { Size, DEBUG_MODE } from '..'

import styles from './app-divider.module.scss'

type AppDividerProps = {
  size?: Size
  border?: boolean
}

const AppDivider = (props: AppDividerProps) => {
  const { size = 'medium', border = false } = props

  const Base = () => (
    <div
      data-testid="app-divider"
      className={classNames(styles.appDivider, styles[size], border && styles.border)}
    />
  )

  if (DEBUG_MODE) {
    return (
      <div className={styles.appDividerContainer} data-testid="app-divider-container">
        <div className={styles.size}>{size}</div>
        <Base />
      </div>
    )
  }

  return <Base />
}

export default AppDivider
