import { ReactNode } from 'react'

import { Form } from 'client/semantic-imports'
import { AppDivider } from 'client/ui-building-blocks'

type AppFormProps = {
  children: ReactNode[]
  onSubmit?: () => void
}

const AppForm = (props: AppFormProps) => {
  const { children, onSubmit } = props

  const filteredChildren = children.filter(child => child)

  return (
    <Form onSubmit={onSubmit}>
      {filteredChildren.map((child, i) => {
        const isLast = i === filteredChildren.length - 1

        return (
          <div key={i}>
            {isLast ? <AppDivider size="small" /> : null}
            {child}
            {!isLast ? <AppDivider size="small" /> : null}
          </div>
        )
      })}
    </Form>
  )
}

export default AppForm
