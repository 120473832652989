import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownProps } from 'semantic-ui-react'
import classNames from 'classnames'

import { Form } from 'client/semantic-imports'
import { AppFormFieldLabel } from 'client/ui-building-blocks'
import { useAppContext } from 'client/hooks'

type AppFormAutoCompleteProps = {
  label: string
  placeholder: string
  value: string[]
  options: { value: string; text: string }[]
  disabled?: boolean
  multiple?: boolean
  required?: boolean
  error?: string
  onChange: (e: ChangeEvent<HTMLInputElement>, data: DropdownProps) => void
}

const AppFormAutoComplete = (props: AppFormAutoCompleteProps) => {
  const { t } = useTranslation()

  const { setShowAppDimmer } = useAppContext()

  const {
    label,
    placeholder,
    value,
    options = [],
    disabled = false,
    multiple = false,
    required = false,
    error = '',
    onChange,
  } = props

  const excludeAllOption = !multiple || required

  const onOpen = () => {
    setShowAppDimmer(true)
  }

  const onClose = () => {
    setShowAppDimmer(false)
  }

  return (
    <div className={classNames('appFormAutoComplete', error && 'error')}>
      <Form.Dropdown
        label={<AppFormFieldLabel label={label} error={error} />}
        placeholder={placeholder}
        value={
          excludeAllOption
            ? value
            : value.length
              ? value.filter(v => v !== 'all')
              : ['all']
        }
        options={
          excludeAllOption || value.length
            ? options
            : [{ value: 'all', text: 'All' }, ...options]
        }
        onChange={(e: ChangeEvent<HTMLInputElement>, data: DropdownProps) => {
          if (e) {
            e.target.value = String(data.value)
            onChange(e, data)
          }
        }}
        onOpen={onOpen}
        onClose={onClose}
        search
        selection
        multiple={multiple}
        disabled={disabled}
        noResultsMessage={t('common.info.nothingToDisplay')}
      />
    </div>
  )
}

export default AppFormAutoComplete
